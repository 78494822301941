import axios from './axios';

const Axios = axios();
const loadConfig = function () {
  return Axios.get('/api/v1/data/config')
    .then(res => res.data)
    .then(json => {
      if (json.status === 'success') {
        return json.config;
      } else {
        return Promise.reject(json.message);
      }
    });
};

const checkConfigStatus = function () {
  return Axios.get('/api/v1/data/configStatus').then(res => res.data);
};

const getCustomStratEditors = function () {
  return Axios.get('/api/v1/data/customStratEditors').then(res => res.data);
};

const checkUserStatus = function () {
  return Axios.get('/api/v1/auth/checkUserStatus').then(res => res.data);
};

const getPairs = function (exchange) {
  const encodedExchange = encodeURIComponent(exchange);

  return Axios.get(`/api/v1/data/getpairs?exchange=${encodedExchange}`).then(res => res.data);
};

const getPairsDetailed = function (exchange, marketType) {
  const encodedExchange = encodeURIComponent(exchange);
  const encodedMarketType = encodeURIComponent(marketType);

  return Axios.get(`/api/v1/data/getpairsDetailed?exchange=${encodedExchange}&marketType=${encodedMarketType}`).then(res => res.data);
};

const autoconfigAPI = function (job, config) {
  return Axios.get(`/api/v1/data/autoconfigAPI?job=${JSON.stringify(job)}&config=${JSON.stringify(config)}`).then(
    res => res.data,
  );
};

const saveChangedConfig = function (config) {
  return Axios.post('/api/v1/settings/updateConfig', { data: config }).then(res => res.data);
};

const getIMAP = function () {
  return Axios.get('/api/v1/settings/getIMAP').then(res => res.data);
};

const getWEBHOOKS = function () {
  return Axios.get('/api/v1/settings/getWEBHOOKS').then(res => res.data);
};

const getTG = function () {
  return Axios.get('/api/v1/settings/getTG').then(res => res.data);
};

const getConnectedExchanges = function () {
  return Axios.get('/api/v1/settings/getExchanges').then(res => res.data);
};

const getTwoFA = function () {
  return Axios.get('/api/v1/settings/getTwoFA').then(res => res.data);
};

const updateWallet = function (oldWall, newWall) {
  return Axios.post('/api/v1/settings/updateWallet', { oldWall, newWall })
    .then(res => res.data)
    .then(data => {
      if (data.status === 'error') {
        return Promise.reject('Failed to update wallet....');
      }
      return data;
    });
};

const updateMasterKey = function ({ exch, oldMK, newMK, newMS, gw }) {
  const encodedExchange = encodeURIComponent(exch);

  return Axios.post('/api/v1/settings/updateMasterKey', {
    encodedExchange,
    oldMK,
    newMK,
    newMS,
    gw,
  })
    .then(res => res.data)
    .then(data => {
      if (data.status === 'error') {
        return Promise.reject('Failed to update MasterKey....');
      }
      return data;
    });
};
const stopBotCore = function () {
  return Axios.post('/api/v1/settings/stopCore', { data: false })
    .then(res => res.data)
    .then(data => {
      if (data.status === 'error') {
        return Promise.reject('Failed to update MasterKey....');
      }
      return data;
    });
};
const startBotCore = function () {
  return Axios.post('/api/v1/settings/startCore', { data: true })
    .then(res => res.data)
    .then(data => {
      if (data.status === 'error') {
        return Promise.reject('Failed to update MasterKey....');
      }
      return data;
    });
};

const havePendingConfigChanges = function (value) {
  return Axios.post('/api/v1/settings/havePendingConfigChanges', { data: value })
    .then(res => res.data)
    .then(data => {
      if (data.status === 'error') {
        return Promise.reject('Failed to set status for havePendingConfigChanges....');
      }
      return data;
    });
};

const loadAvailableGUIData = function (keys) {
  return Axios.post('/api/v1/loadAvailableGUIData', { keys })
    .then(res => res.data)
    .then(data => {
      if (!data || data.status === 'error') {
        return Promise.reject('Failed to get coremem');
      }
      return data;
    });
};

const getCorememData = function () {
  return Axios.post('/api/v1/getCorememData')
    .then(res => res.data)
    .then(data => {
      if (!data || data.status === 'error') {
        return Promise.reject('Failed to get coremem');
      }
      return data;
    });
};

const login = async function (password, twoFA, walletAddress) {
  return Axios.post('/api/v1/auth/login', { password, twoFA, walletAddress })
    .then(res => res.data)
    .then(user => {
      localStorage.setItem('token', 'Bearer ' + user.token);
    });
};

const register = function (password, twoFA) {
  return Axios.post('/api/v1/auth/register', { password, twoFA })
    .then(res => res.data)
    .then(user => {
      localStorage.setItem('token', 'Bearer ' + user.token);
    });
};

const checkTwoFA = async (password, twoFA) => {
  return Axios.post('/api/v1/auth/checkTwoFA', { password, twoFA })
    .then(res => res.data)
}

const registerNewLicenses = function (wallet, licenses, orderNumber) {
  return Axios.post('/api/v1/settings/registerNewLicenses', { wallet, licenses, orderNum: orderNumber }).then(res => res.data);
};

const editRegisteredKeys = function (wallet, newLicenses, verifyExchange) {
  return Axios.post('/api/v1/settings/editRegisteredKeys', { wallet, newLicenses, verifyExchange }).then(res => res.data);
};

const testKeys = function (data) {
  return Axios.post('/api/v1/trade/testKeys', { data });
};

const placeBuyOrder = function (data) {
  return Axios.post('/api/v1/trade/placeBuyOrder', { data }).catch(error => {
    console.log({ error });
  });
};

const placeMarketBuyOrder = function (data) {
  return Axios.post('/api/v1/trade/placeMarketBuyOrder', { data }).catch(error => {
    console.log({ error });
  });
};

const placeBuyStopLimitOrder = async (data) => {
  return Axios.post('/api/v1/trade/placeBuyStopLimitOrder', { data }).catch(error => {
    console.log({ error });
  });
};

const placeBuyTrailingStopOrder = async (data) => {
  return Axios.post('/api/v1/trade/placeBuyTrailingStopOrder', { data }).catch(error => {
    console.log({ error });
  });
};

const placeBuyOCO = async (data) => {
  return Axios.post('/api/v1/trade/placeBuyOCO', { data }).catch(error => {
    console.log({ error });
  });
};

const placeSellOrder = function (data) {
  return Axios.post('/api/v1/trade/placeSellOrder', { data }).catch(error => {
    console.log({ error });
  });
};

const placeMarketSellOrder = function (data) {
  return Axios.post('/api/v1/trade/placeMarketSellOrder', { data }).catch(error => {
    console.log({ error });
  });
};

const placeSellStopLimitOrder = async (data) => {
  return Axios.post('/api/v1/trade/placeSellStopLimitOrder', { data }).catch(error => {
    console.log({ error });
  });
};
const placeSellTrailingStopOrder = async (data) => {
  return Axios.post('/api/v1/trade/placeSellTrailingStopOrder', { data }).catch(error => {
    console.log({ error });
  });
};
const placeSellOCO = async (data) => {
  return Axios.post('/api/v1/trade/placeSellOCO', { data }).catch(error => {
    console.log({ error });
  });
};

const placeCloseOrder = function (data) {
  return Axios.post('/api/v1/trade/placeCloseOrder', { data }).catch(error => {
    console.log({ error });
  });
};

const placeCloseMarketOrder = function (data) {
  return Axios.post('/api/v1/trade/placeCloseMarketOrder', { data }).catch(error => {
    console.log({ error });
  });
};
const cancelOrder = function (data) {
  return Axios.post('/api/v1/trade/placeCancelOrder', JSON.stringify({ data }), {
    headers: {
      'Content-Type': 'application/json'
    }
  }).catch(error => {
    console.log({ error });
  });
};

const sendExportedIndicatorsData = function (exchange, pair, data) {
  const encodedExchange = encodeURIComponent(exchange);

  return Axios.post('/api/v1/data/updateExportedIndicatorsData', {
    encodedExchange,
    pair,
    data,
  });
};

const getStateFile = function (filename) {
  return Axios.post('/api/v1/data/getStateFile', { filename })
    .then(res => res.data)
    .then(data => {
      if (!data || data.status === 'error') {
        return Promise.reject('Failed to get state file');
      }
      return data;
    });
};

const getBackupFile = function (filename) {
  return Axios.post('/api/v1/data/getBackupFile', { filename })
    .then(res => res.data)
    .then(data => {
      if (!data || data.status === 'error') {
        return Promise.reject('Failed to get backup file');
      }
      return data;
    });
};

const getAcVarFile = function (filename) {
  return Axios.post('/api/v1/data/getAcVarFile', { filename })
    .then(res => res.data)
    .then(data => {
      if (!data || data.status === 'error') {
        return Promise.reject('Failed to get backup file');
      }
      return data;
    });
};

const getStrategyFile = async function (filename) {
  return Axios.post('/api/v1/data/getStrategyFile', { filename })
    .then(res => res.data)
    .then(data => {
      if (!data || data.status === 'error') {
        return Promise.reject('Failed to get strategy file');
      }
      return data;
    });
};

const writeStrategyFile = function (filename, document) {
  return Axios.post('/api/v1/data/writeStrategyFile', { filename, document })
    .then(res => res.data)
    .then(data => {
      if (!data || data.status === 'error') {
        return Promise.reject('Failed to write strategy file');
      }
      return data.status;
    });
};

const deleteStrategyFile = function (filename) {
  return Axios.post('/api/v1/data/deleteStrategyFile', { filename })
    .then(res => res.data)
    .then(data => {
      if (!data || data.status === 'error') {
        return Promise.reject('Failed to delete strategy file');
      }
      return data.status;
    });
};

const listStateFiles = function () {
  return Axios.get('/api/v1/data/listStateFiles').then(res => res.data);
};

const listAcVarFiles = function () {
  return Axios.get('/api/v1/data/listAcVarFiles').then(res => res.data);
};

const listBackupFiles = function () {
  return Axios.post('/api/v1/data/listBackupFiles').then(res => res.data);
};

const listStrategyFiles = function () {
  return Axios.get('/api/v1/data/listStrategyFiles').then(res => res.data);
};

const checkOrderNumber = function (orderNumber) {
  return Axios.get(`/api/v1/data/checkOrderNumber?orderNumber=${orderNumber}`).then(res => res.data);
};

const checkGunthyWallet = function (gunthyWallet) {
  return Axios.get(`/api/v1/data/checkGunthyWallet?gunthyWallet=${gunthyWallet}`).then(res => res.data);
};

const getSgsDetails = function () {
  return Axios.get(`/api/v1/gunthy.org/getSgsDetails`).then(res => res.data);
};


export const invitePeople = async function ({ gunthy_wallet, email }) {
  return Axios.post('/api/v1/email/invite', { gunthy_wallet, email }).then(res => res.data);
};

export const saveStrategyFunctionApi = async (strategyName, strategyFunction) => {
  return Axios.post('/api/v1/data/writeStrategyFile', { filename: strategyName, document: strategyFunction }).then(
    res => res.data,
  );
};

const listCustomEditorFiles = function () {
  const response = Axios.get('/api/v1/data/listCustomEditorFiles').then(res => res.data);
  console.log(response)
  return response
};

const getCustomEditorFile = async function (filename) {
  return await Axios.post('/api/v1/data/getCustomEditorFile', { filename })
    .then(res => res.data)
    .then(data => {
      if (!data || data.status === 'error') {
        return Promise.reject('Failed to get editor file');
      }
      return data;
    });
};

const writeCustomEditorFile = function (filename, document) {
  return Axios.post('/api/v1/data/writeCustomEditorFile', { filename, document })
    .then(res => res.data)
    .then(data => {
      if (!data || data.status === 'error') {
        return Promise.reject('Failed to write editor file');
      }
      return data.status;
    });
};

const writeAutoConfigFile = function (filename, document) {
  return Axios.post('/api/v1/data/writeAutoConfigFile', { filename, document })
    .then(res => res.data)
    .then(data => {
      if (!data || data.status === 'error') {
        return Promise.reject('Failed to write editor file');
      }
      return data.status;
    });
};

const getExoCustomEditorFile = async function (filename) {
  return await Axios.post('/api/v1/data/getExoCustomEditorFile', { filename })
    .then(res => res.data)
    .then(data => {
      if (!data || data.status === 'error') {
        return Promise.reject('Failed to get editor file');
      }
      return data;
    });
};

const writeExoCustomEditorFile = function (filename, document) {
  return Axios.post('/api/v1/data/writeExoCustomEditorFile', { filename, document })
    .then(res => res.data)
    .then(data => {
      if (!data || data.status === 'error') {
        return Promise.reject('Failed to write editor file');
      }
      return data.status;
    });
};



export const getTextCompletion = function (context, userInput) {
  return Axios.post('/api/v1/data/getTextCompletion', { context, userInput })
    .then(res => res.data)
    .then(data => {
      if (!data || data?.status === 'error') {
        return 'An error happened, maybe your request is too long. Try again.'
      }
      return data.message;
    });
};

export const getIrisTrend = function (exchange, pair, timeframes) {
  return Axios.post('/api/v1/data/getIrisTrend', { exchange, pair, timeframes })
    .then(res => res.data)
    .then(data => {
      if (!data || data?.status === 'error') {
        return 'An error happened while fetching Iris trend.'
      }
      return data.message;
    });
};

export const getNotes = function () {
  return Axios.post('/api/v1/data/getNotes', {})
    .then(res => res.data)
    .then(data => {
      if (!data || data?.status === 'error') {
        return 'An error happened while fetching notes.'

      }
      return data.message;
    });
};

export const setNotes = function (document) {
  return Axios.post('/api/v1/data/setNotes', { document })
    .then(res => res.data)
    .then(data => {
      if (!data || data?.status === 'error') {
        return 'An error happened while saving notes.'
      }
      return data.message;
    });
};

export default {
  loadConfig,
  saveChangedConfig,
  checkUserStatus,
  getConnectedExchanges,
  getIMAP,
  getWEBHOOKS,
  getTG,
  getTwoFA,
  login,
  checkTwoFA,
  updateWallet,
  updateMasterKey,
  stopBotCore,
  startBotCore,
  getCorememData,
  register,
  registerNewLicenses,
  placeBuyOrder,
  placeMarketBuyOrder,
  placeBuyStopLimitOrder,
  placeBuyTrailingStopOrder,
  placeBuyOCO,
  placeSellOrder,
  placeMarketSellOrder,
  placeSellStopLimitOrder,
  placeSellTrailingStopOrder,
  placeSellOCO,
  placeCloseOrder,
  placeCloseMarketOrder,
  cancelOrder,
  getPairs,
  autoconfigAPI,
  sendExportedIndicatorsData,
  havePendingConfigChanges,
  getStateFile,
  getStrategyFile,
  getBackupFile,
  getAcVarFile,
  writeStrategyFile,
  deleteStrategyFile,
  listStateFiles,
  listBackupFiles,
  listStrategyFiles,
  listAcVarFiles,
  checkConfigStatus,
  getPairsDetailed,
  testKeys,
  checkOrderNumber,
  checkGunthyWallet,
  loadAvailableGUIData,
  inviatePeople: invitePeople,
  getCustomStratEditors,
  listCustomEditorFiles,
  getCustomEditorFile,
  writeCustomEditorFile,
  writeAutoConfigFile,
  getIrisTrend,
  getNotes,
  setNotes,
  editRegisteredKeys,
  getSgsDetails
};
